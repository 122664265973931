export class Plan {
  constructor(
    public planId = 1,
    public productId = 1,
    public division = 1,
    public planName = ''
  ) {}
}

export const otegaruPlans = [
  { value: 23, label: '月額定額制' },
  { value: 8, label: '【旧】月額定額制' },
  { value: 9, label: '【旧】従量課金制' },
  { value: 11, label: '特別プラン' },
];

export const onpremisePlans = [
  { value: 12, label: 'パッケージ販売(買取)' },
  { value: 13, label: '年間定額制' },
  { value: 10, label: '収入分配型' },
];

export const otegaruPlanTypes = ['ブック用', 'ブック＆動画用'];
